<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item>
        <Locale />
      </b-nav-item>

      <b-nav-item-dropdown
        class="dropdown-notification mr-25"
        menu-class="dropdown-menu-media"
        right
      >
        <template #button-content>
          <b-avatar
            v-b-tooltip.hover.bottom="$t('notification')"
            size="40"
            :badge="badgeNotif.toString()"
          >
            <feather-icon
              icon="BellIcon"
              size="21"
            />
          </b-avatar>
        </template>
        <li class="dropdown-menu-header">
          <div class="dropdown-header d-flex">
            <h4 class="notification-title mb-0 mr-auto">
              {{ $t('notifications') }}
            </h4>
            <!-- <b-badge
              pill
              variant="light-primary"
            >
              2 New
            </b-badge> -->
          </div>
        </li>
        <!-- notif content -->
        <!-- Notifications -->
        <vue-perfect-scrollbar
          v-once
          class="scrollable-container media-list scroll-area"
          tagname="li"
          :setting="{
            maxScrollbarLength: 60,
            wheelPropagation: false,
          }"
        >
          <!-- Account Notification -->
          <b-link
            :to="{name:'DeliveryHistory'}"
          >
            <b-media
              v-show="getstatus"
            >
              <template #aside>
                <b-avatar
                  size="32"
                >
                  <feather-icon
                    icon="ArchiveIcon"
                    size="21"
                  />
                </b-avatar>
              </template>
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ $t('delivery_status') }}
                </span>
              </p>
              <small class="notification-text">{{ $t('delivery_status_message') }}</small>
            </b-media>
          </b-link>
          <b-link
            :to="{name:'DeliveryHistory'}"
          >
            <b-media
              v-show="getdocs"
            >
              <template #aside>
                <b-avatar
                  size="32"
                >
                  <feather-icon
                    icon="ArchiveIcon"
                    size="21"
                  />
                </b-avatar>
              </template>
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ $t('delivery_documents') }}
                </span>
              </p>
              <small class="notification-text">{{ $t('delivery_documents_message') }}</small>
            </b-media>
          </b-link>
        </vue-perfect-scrollbar>
      </b-nav-item-dropdown>

      <b-nav-item
        class="top-right-menu"
      >
        <b-avatar
          v-b-tooltip.hover.bottom="$t('cart')"
          :badge="cartItemsCount.toString()"
          size="40"
          :to="{name:'mycart'}"
        >
          <feather-icon
            icon="ShoppingCartIcon"
            size="20"
          />
        </b-avatar>
      </b-nav-item>
      <b-nav-item
        class="top-right-menu"
      >
        <b-avatar
          v-b-tooltip.hover.bottom="$t('my_profile')"
          :src="user.pic"
          size="40"
          :to="{name : 'myprofile'}"
        >
          <!-- <feather-icon
            icon="UserIcon"
            size="20"
          /> -->
        </b-avatar>
      </b-nav-item>
      <b-nav-item
        v-b-tooltip.hover.bottom="$t('logout')"
        class="top-right-menu"
        @click="logoutSession"
      >
        <b-avatar
          size="40"
        >
          <feather-icon
            icon="LogOutIcon"
            size="20"
          />
        </b-avatar>
      </b-nav-item>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import store from '@/store/index'
import { getUserData } from '@/auth/utils'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar,
    Locale,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BAvatar,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      user: { name: 'Name', pic: null },
      roles: { 1: 'Admin', 2: 'B2BAccount', 3: 'SmartTruck' },
      notifications: [],
    }
  },
  computed: {
    cartItemsCount() {
      const count = this.$store.getters.getCartItemsLength;
      return Number.isFinite(count) ? count : 0;
    },
    getstatus() {
      return this.$store.getters.getStatus > 0
    },
    getdocs() {
      return this.$store.getters.getDocuments > 0
    },
    badgeNotif() {
      let badge = 0
      if (this.$store.getters.getStatus > 0) {
        badge += badge
      }
      if (this.$store.getters.getDocuments > 0) {
        badge += badge
      }
      return badge
    },
  },
  mounted() {
    this.$http.get('notifyYesterdayDelivery').then(response => {
      this.$store.commit('reset')
      this.$store.commit('set', response.data)
    })
  },
  created() {
    this.user = getUserData()
    this.user.pic = this.user.pic ? `${this.$s3URL}uploads/user/${this.user.pic}` : `${this.$s3URL}uploads/user/imagenotavailable.png`
  },
  methods: {
    sets() {
      this.show = true
    },
    goProfile() {
      this.$router.replace('/profile')
    },
    logoutSession() {
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      store.commit('setStateValue') // udpating the store
      this.$router.replace('/login')
    },
  },
}
</script>
